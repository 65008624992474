body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Poppins', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
}
code {
  font-family: -apple-system, 'Poppins', serif !important;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homeBackgroundWrapper {
  background-image: url("./assets/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.message-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.message-container.show {
  opacity: 1;
  visibility: visible;
}

.message-container.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@media only screen and (max-width: 600px) {
  body {
    background: white;
  }
}
@media print {
  body {
    -webkit-print-color-adjust: exact; /* Ensures colors are printed accurately in Chrome */
    color: black;
    background: white;
  }

  @page {
    size: letter landscape; /* auto is the initial value */
    margin: 5mm;
  }

  .MuiAppBar-root, .MuiDrawer-root, .no-print {
    display: none; /* Hide elements not necessary for printing */
  }

  .content-container {
    width: 100%; /* Adjust layout for printing */
    padding: 0;
    margin: 0 auto;
  }
  .MuiDataGrid-root {
    transform: scale(0.74);
    width: 135%;
    transform-origin: top left;
    height: auto !important;
  }
}